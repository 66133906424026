:root {
  --max-width: 1440px;
  --max-width-post: 42rem;
  --border-radius: 12px;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --font-family-secondary: "Lexend", -apple-system, sans-serif;
  --font-family-third: "Dancing Script", -apple-system, sans-serif;

  --font-size-title: 56px;
  --line-height-title: 76px;
  --innerWidth: 1280px;
  --innerProductWidth: 1170px;
  --innerContent: 820px;
  --innerContentFull: 1200px;
  --innerImage: 1060px;

  --pad-image: 52px;
  --pad-inner: 52px;
  --pad-inner-child: 152px;
  --pad-inner-large: 425px;

  --header-height: 80px;
  --text-header: 14px;
  --body-color: #444444;
  --primary-color: #1f2834;
  --secondary-color: #cc910f;
  // Color Banner
  --banner-title-color: var(--primary-color);
  // Color Card
  // color: #111827
  --grey-1: #414241;
  --grey-2: #b4b4b4;
  --grey-3: #d6d6d6;
  --medium-grey: #6d6e76;
  --dark-grey: #4c4c4c;
  --darkest-grey: #262626;

  // Color Article
  --bg-article: var(--white);
  --title-article: rgba(var(--grey-900), 0.9);
  --category-color: rgba(var(--grey-900), 0.9);
  --paragraph-color: rgba(0, 0, 0, 0.85);
  --yellow-label-color: rgb(133 77 14);
  --white: #fff;
  --black: #000;

  --heading-color: #292929;
  --primary-price-color: #c91f28;
  --secondary-price-color: #7b7b7b;
  --price-sale-color: #c91f28;
  --highlight-color: #c91f28;
  --light-red: #ffa0a5;

  --purple: #6f42c1;
  --light-purple: #c1a0ff;
  --light-yellow: #fbf6ea;
  --bg-color-light: #ebecf0;
  --bg-code: #ebecf0;
  --bg-course: #ebecf0;
  --bg-grain-light: url(/bg-grain.png);
  --bg-grain: var(--bg-grain-light);
  --bg-tabs: #f9f9f9;

  // Anchor
  --anchor-color: var(--grey-2);
  // Button
  --bg-primary-button: var(--primary-color);
  --text-primary-button: var(--white);
  --hover-primary-button: var(--secondary-color);
  --hover-text-primary-button: var(--white);
  // Input
  --input-bg-color: #2e281f;
  --input-hl-color: #ae905e;
  --input-text-color: var(--body-color);
  --input-error-color: #c91f28;
  // Divider
  --divider-color: var(--anchor-color);
  --divider-reverse-color: var(--dark-grey);

  --layer-1: #0e0e0e;
  // --layer-2: #1e2235;
  --layer-2: #202020;
  --layer-3: #252a41;
  --layer-4: #323855;
  --layer-5: #424867;

  --line-height-body1: clamp(1.125rem, 1.375rem + 0.1786vw, 1.25rem);
  --line-height-body2: clamp(1.125rem, 1.25rem + 0.2174vw, 1.25rem);

  --step--2: clamp(0.6075rem, 0.5907rem + 0.0839vw, 0.6663rem);
  --step--1: clamp(0.7294rem, 0.7046rem + 0.1241vw, 0.8163rem);
  --step-0: clamp(0.875rem, 0.8393rem + 0.1786vw, 1rem);
  --step-1: clamp(1.05rem, 1rem + 0.25vw, 1.225rem);
  --step-2: clamp(1.26rem, 1.1913rem + 0.3438vw, 1.5006rem);
  --step-3: clamp(1.5119rem, 1.4187rem + 0.4661vw, 1.8381rem);
  --step-4: clamp(1.8144rem, 1.6894rem + 0.625vw, 2.2519rem);
  --step-5: clamp(2.1775rem, 2.0114rem + 0.8304vw, 2.7588rem);

  --line-height--2: clamp(0.7813rem, 0.875rem + 0.0839vw, 0.8rem);
  --line-height--1: clamp(0.9375rem, 1.125rem + 0.1241vw, 1rem);
  --line-height-0: clamp(1.125rem, 1.375rem + 0.1786vw, 1.25rem);
  --line-height-1: clamp(1.125rem, 1.3rem + 0.25vw, 1.5rem);
  --line-height-2: clamp(1.65rem, 1.65rem + 0.3438vw, 2.8rem);
  --line-height-3: clamp(2rem, 2.1rem + 0.4661vw, 2.375rem);
  --line-height-4: clamp(2.3325rem, 2.5rem + 0.625vw, 3.0519rem);
  --line-height-5: clamp(2.7994rem, 2.5rem + 0.8304vw, 3.815rem);

  --width-thumbs: 7.5rem;
  // --header-height: 56px;
  --site-width: 1280px;
  --radius: 0.375rem;
  --radius-large: 0.75rem;
  --radius-xl: 2rem;
  --radius-auto: Max(0px, Min(var(--radius), calc((100vw - 4px - 100%) * 9999))) /
    var(--radius);
  --radius-large-auto: Max(
      0px,
      Min(var(--radius-large), calc((100vw - 4px - 100%) * 9999))
    ) / var(--radius-large);
  --transition-func: cubic-bezier(0.17, 0.67, 0.5, 0.71);
  --transition-time: 100ms;
  --transition-props: var(--transition-func) var(--transition-time);
  --focus-ring: 0 0 0 2px var(--base-inverted), 0 0 0 4px var(--focus);
  // --c: rgb(111, 66, 193);
  --grey-50: 250, 250, 250;
  --grey-100: 245, 245, 245;
  --grey-200: 229, 229, 229;
  --grey-500: 115, 115, 115;
  --grey-700: 64, 64, 64;
  --grey-900: 23, 23, 23;

  --accent-brand-lighter-rgb: var(--indigo-500);
  --accent-brand-rgb: var(--indigo-600);
  --accent-brand-darker-rgb: var(--indigo-700);

  --card-linear-gradient: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}
.bg-grain {
  background-color: var(--bg-color-light);
  background-image: var(--bg-grain);
  background-size: 350px auto;
}
.inner {
  width: 100%;
  max-width: var(--innerWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner);
}
.inner-child {
  width: 100%;
  max-width: var(--innerWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner-child);
}
.inner-content {
  @apply bg-white rounded;
  width: 100%;
  max-width: var(--innerContent);
  margin: 1rem auto;
  // border: 1px rgb(239, 239, 239) solid;
  padding: 2rem var(--pad-inner);

  &.full {
    padding-left: 0;
    padding-right: 0;
  }
}
.box-thank-page {
  @apply flex flex-col items-center justify-center h-[100vh] w-[100wh];
}
.inner-thank-page {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem var(--pad-inner);
}
.inner-content-left {
  width: 60%;
  max-width: var(--innerContent);
  margin: 1rem initial;

  // padding-right: var(--pad-inner);
}
.inner-content-right {
  width: 40%;
}
.inner-content-full {
  @apply bg-white rounded;
  width: 100%;
  max-width: var(--innerContentFull);
  margin: 1rem auto;
  padding: 2rem var(--pad-inner);
}

.inner-image {
  @apply bg-white rounded;
  width: 100%;
  max-width: var(--innerImage);
  padding: 0rem var(--pad-image);
  margin: 0rem auto;
}
.inner-product {
  width: 100%;
  max-width: var(--innerProductWidth);
  margin: 0 auto;
  padding: 0 var(--pad-inner);
}
.course-inner-child {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}
.content-inner-child {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 var(--pad-inner-child);
}
.texts {
  &-default {
    font-family: var(--font-family-secondary);
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 300;
    color: var(--dark-grey);
    letter-spacing: 1%;
  }
}
.pv-nav {
  @apply relative  h-full;
  .pv-label {
    @apply flex uppercase text-grey-1 font-semibold h-full items-center justify-center;
    font-family: var(--font-family-secondary);

    font-size: 16px;
    letter-spacing: 0.1em;
    // font-weight: 800;
  }
  .pv-child-nav {
    @apply absolute scale-y-0 duration-300 ease-in-out py-2 bg-white origin-top whitespace-nowrap;
    background-color: var(--layer-2);
    font-family: var(--font-family-secondary);

    font-size: 16px;
    letter-spacing: 0.1em;

    // top: 100%;
  }
  &:hover {
    .pv-child-nav {
      @apply scale-y-100;
    }
  }
}

.pv-section-title {
  font-size: var(--font-size-title);
  line-height: var(--line-height-title);
}
.pv-section-subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
}
.section {
  height: clamp(680px, 100vh, 1080px);
}

// @tailwind base; /* Preflight will be injected here */

@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import "@gstyles/tailwind/style.scss";
@import "./locomotive-scroll.scss";
@import "./course.scss";
@import "./react-modal.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  color: var(--body-color);
  font-size: 16px;
  line-height: 30px;
}
main {
  position: relative;
  min-height: 100vh;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
// .code {
//   background-color: var(--bg-code);
// }

.bg-page {
  background-color: var(--bg-color);
}
.label-title {
  font-family: var(--font-family-secondary);
  color: var(--title-article);
  font-size: 2.2rem;
  line-height: 3.25rem;
  margin-bottom: 2rem;
}
.article {
  font-size: 1.25rem;
  line-height: 1.5rem;
  // color: #292929;
  .title {
    color: var(--title-article);
  }
  strong {
    font-weight: 600;
    color: var(--heading-color);
  }
  code {
    font-size: 1.15rem;
    line-height: 1.5rem;
    span {
      font-size: 1rem;
    }
  }
  h1 {
    font-family: var(--font-family-secondary);
    font-size: 2.5rem;
    line-height: 3.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
    letter-spacing: 0.0938rem;
    code {
      font-size: 2.5rem;
      line-height: 3.75rem;
    }
  }
  h2 {
    font-family: var(--font-family-secondary);

    // font-family: var(--font-family-fourth);
    font-size: 1.9rem;
    line-height: 2.7rem;
    code {
      font-size: 1.9rem;
      line-height: 2.7rem;
    }
  }
  p {
    margin-bottom: 1rem;
    text-align: justify;
    font-weight: 300;
  }
  .article-content {
    background-color: var(--bg-article);
  }
  h3,
  h4 {
    margin-bottom: 1rem;
  }
}
.card {
  @apply cursor-pointer block bg-white rounded-md;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: var(--light-yellow);
  }
  .category {
    @apply uppercase;
    font-family: var(--font-family-secondary);
    font-weight: 300;
    font-size: 0.875rem;

    // font-size: 0.875rem;
    color: var(--dark-grey);
    // &.list-item {

    // }
  }
  h3,
  h4 {
    font-size: var(--step-2);
    line-height: var(--line-height-2);
    font-family: var(--font-family-secondary);
  }
}
.youtube {
  font-size: 1.125rem;
  p {
    font-weight: 400;
    letter-spacing: 0.2px;
  }
  .review-summary {
    display: none;
  }
  .item {
  }
}
.eh-title {
  font-family: var(--font-family-secondary);
}
h1,
.h1 {
  color: var(--heading-color);
  font-size: var(--step-5);
  line-height: var(--line-height-5);
  font-weight: 500;
}
h2,
.h2 {
  color: var(--heading-color);
  font-size: var(--step-4);
  line-height: var(--line-height-4);
  font-weight: 500;
}
h3,
.h3 {
  color: var(--heading-color);
  font-size: var(--step-3);
  line-height: var(--line-height-3);
  font-weight: 500;
}
h4,
.h4 {
  color: var(--heading-color);
  font-size: var(--step-2);
  line-height: var(--line-height-2);
  font-weight: 500;
}
h5,
.h5 {
  color: var(--heading-color);
  font-size: var(--step-1);
  line-height: var(--line-height-1);
  font-weight: 500;
}
h6,
.h6 {
  color: var(--heading-color);
  font-size: var(--step-0);
  line-height: var(--line-height-0);
  font-weight: 500;
}
.body1 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: var(--dark-grey);

  // line-height: var(--line-height-body1);
}
.body2 {
  font-size: var(--step--1);
  color: var(--dark-grey);

  line-height: var(--line-height-body2);
}

@media (max-width: 1280px) {
  :root {
    --pad-image: 40px;
    --pad-inner: 40px;
    --pad-inner-child: 152px;
    --pad-inner-large: 425px;
  }
}
@media (max-width: 1023px) {
  :root {
    --pad-image: 24px;
    --pad-inner: 24px;
    --pad-inner-child: 80px;
    --pad-inner-large: 425px;
  }
}
@media (max-width: 576px) {
  :root {
    --pad-image: 0px;
    --pad-inner: 16px;
    --pad-inner-child: 16px;
    --pad-inner-large: 425px;
  }
  body {
    font-size: 14px;
    line-height: 26px;
  }
  .pv-section-subtitle {
    font-size: 1.15rem;
    line-height: 1.6;
  }
  .article {
    font-size: 1.125rem;
    code {
      font-size: 1.125rem;
    }
    h1 {
      font-size: 2rem;
      line-height: 2.75rem;
      code {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
    h2 {
      font-size: 1.5rem;
      line-height: 2.2rem;
      code {
        font-size: 1.5rem;
        line-height: 2.2rem;
      }
    }
    .blockQuote {
      font-size: 1.5rem;
      padding: 0.5rem;
      padding-left: 0.625rem;
      border-left-width: 0.3em;
    }
  }
  .youtube {
    font-size: 1rem;
    .item {
      &-title {
        @apply flex-col gap-3;
      }
    }
  }
}

// Pages

#banner {
  .pv-img {
    img {
      opacity: 0;
      transform: scale(1.1);
      width: 100%;
      transition: opacity 2s, transform 7s linear;
    }
    &.is-inview {
      img {
        opacity: 1;
      }
    }
  }

  .swiper-slide-active {
    .pv-img {
      &.is-inview {
        img {
          opacity: 1;
          transform: scale(1.18);
        }
      }
    }
  }
}

#cursor {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 150px;
  left: 0;
  margin: -75px;
  background-color: white;
  mix-blend-mode: difference;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  opacity: 1;
  transform: scale(0.4);
  transition: opacity 0.2s ease-out, transform 0.2s ease-out,
    -webkit-transform 0.2s ease-out, -moz-transform 0.2s ease-out,
    -o-transform 0.2s ease-out;

  z-index: 9999999999;
  &.show {
    opacity: 1;
  }
}
.rotate-x {
  &-0 {
    transform: rotateX(0deg);
  }
  &-180 {
    transform: rotateX(180deg);
  }
}
// @import "./post.scss";
// @media (max-width: 1280px) {

// }
// @media (max-width: 1023px) {

// }
// @media (max-width: 576px) {

// }
@keyframes eh-fadeIn {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
[data-theme="dark"] {
  --bg-color: var(--layer-1);
  --bg-article: var(--layer-2);
  --bg-code: #282a36;
  --bg-course: #171717;
  --title-color: var(--white);
  --paragraph-color: #d4d4d4;
  --yellow-label-color: rgb(253 224 71);
  --heading-color: var(--white);
  --title-article: var(--white);
  --gradient-cotton-candy-from: #97c5ff;
  --gradient-cotton-candy-to: #ebd5fe;
  --primary-price-color: #ff3642;
  --bg-tabs: var(--layer-2);
  --bg-primary-button: #ebecf0;
  --primary-button: var(--primary-color);
  --text-primary-button: var(--primary-color);
  --hover-text-primary-button: #ebecf0;
  // --divider-color: var(--layer-4);

  --secondary-color: #e8cf98;
  --banner-title-color: #fff;
  // Button
  --hover-primary-button: #cc910f;
  // Input
  --input-bg-color: var(--paragraph-color);
  --input-hl-color: var(--secondary-color);
  --input-text-color: var(--paragraph-color);
  // Anchor
  --anchor-color: var(--dark-grey);
  // Divider
  --divider-reverse-color: var(--grey-2);

  .texts {
    &-default {
      color: var(--white);
    }
  }
  .gradient-cotton-candy {
    background: -webkit-linear-gradient(
      315deg,
      var(--gradient-cotton-candy-from),
      var(--gradient-cotton-candy-to)
    );
  }
  .text-gradient-cotton-candy {
    background: -webkit-linear-gradient(
      315deg,
      var(--gradient-cotton-candy-from),
      var(--gradient-cotton-candy-to)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .bg-grain {
    background-color: var(--bg-color);
  }
  .paragraph {
    color: var(--paragraph-color);
  }
  .li {
    color: var(--paragraph-color);
  }
  .card {
    background-color: var(--layer-2);
    .title {
    }
    .description {
    }
    .category {
    }

    // &.list-item {
    &:hover {
      background-color: var(--layer-4);
    }
    // }
  }
  .article {
    background-color: var(--layer-1);
  }
  .product-detail {
    table {
      td {
        color: var(--paragraph-color);
      }
    }
  }
}
