@import "./text-ellipsis.scss";
/**
 * Inter
 */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/inter/Inter-ExtraBold.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  src: url("../assets/fonts/inter/Inter-ExtraBoldItalic.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/inter/Inter-Bold.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/inter/Inter-BoldItalic.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/inter/Inter-SemiBold.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/inter/Inter-SemiBoldItalic.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/inter/Inter-Medium.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/inter/Inter-MediumItalic.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/inter/Inter-Regular.otf") format("opentype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/inter/Inter-Italic.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/inter/Inter-Light.otf") format("opentype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/inter/Inter-LightItalic.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/inter/Inter-ExtraLight.otf") format("opentype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  src: url("../assets/fonts/inter/Inter-ExtraLightItalic.otf")
    format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/inter/Inter-Thin.otf") format("opentype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  src: url("../assets/fonts/inter/Inter-ThinItalic.otf") format("opentype");
  font-display: swap;
}

/**
 * Dancing Script
 */

@font-face {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/dancing_script/DancingScript-Bold.ttf")
    format("truetype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/dancing_script/DancingScript-SemiBold.ttf")
    format("truetype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

@font-face {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/dancing_script/DancingScript-Medium.ttf")
    format("truetype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

@font-face {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/dancing_script/DancingScript-Regular.ttf")
    format("truetype");
  font-display: swap;
  // comment font-display sẽ làm font bị flash.
}

/**
 * Lato
 */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/lato/Lato-Regular.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/lato/Lato-Italic.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/lato/Lato-Semibold.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/lato/Lato-Bold.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}

// UTM Avo

@font-face {
  font-family: "UTM Avo";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/utm_avo/UTMAvo.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "UTM Avo";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/utm_avo/UTMAvo-Italic.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "UTM Avo";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/utm_avo/UTMAvo-BoldItalic.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "UTM Avo";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/utm_avo/UTMAvo-Bold.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}

// Lexend
@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/lexend/Lexend-ExtraLight.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/lexend/Lexend-Light.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/lexend/Lexend-Regular.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/lexend/Lexend-Medium.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/lexend/Lexend-SemiBold.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
@font-face {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/lexend/Lexend-Bold.ttf") format("truetype");
  //   font-display: swap;
  font-display: fallback;
  // comment font-display sẽ làm font bị flash.
}
