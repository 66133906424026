html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
  transform: perspective(1px);
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction="horizontal"] .c-scrollbar {
  width: 100%;
  height: 10px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}
[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  right: auto;
  bottom: 0;
}
[data-scroll] {
  // opacity: 0;
  transition: opacity 0.1s linear;
}
[data-scroll].is-inview {
  opacity: 1;
}

// .pv-img {
//   img {
//     opacity: 0;
//     transform: scale(1.4);
//     transition-duration: 1.2s;
//     width: 100%;
//   }
//   svg {
//     opacity: 0;
//     transform: scale(1.4);
//     transition-duration: 1.2s;
//   }
//   &.is-inview {
//     img {
//       opacity: 1;
//       transform: scale(1);
//     }
//     svg {
//       opacity: 1;
//       transform: scale(1);
//     }
//   }
// }
.pv-img-wrapper {
  .pv-img {
    opacity: 0;
    transform: scale(1.4);
    transition-duration: 1s;
    width: 100%;
    height: 100%;
    .fit {
      transform: scale(1.1);
    }
    &.is-inview {
      opacity: 1;
      transform: scale(1.001);
      .fit {
        transform: scale(1.1);
      }
    }
  }
  &.nonAnimated {
    .pv-img {
      opacity: 1;
    }
  }
}
