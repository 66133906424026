.course-anchor {
  @apply flex flex-col border-l-[2px] ml-[6px];
  border-color: var(--anchor-color);
}

.course {
  font-size: 1.125rem;
  line-height: 1.25;
  background-color: var(--bg-course);
  letter-spacing: 0.4px;
  .switch-icon {
    @apply hidden absolute px-2;
  }
  .modules {
  }
  .content {
    max-width: calc(100% - 340px);
  }

  // color: #292929;
  .title {
    color: var(--title-article);
  }
  strong {
    font-weight: 600;
    color: var(--heading-color);
  }
  code {
    font-size: 1.15rem;
    line-height: 1.5rem;
    span {
      font-size: 1rem;
    }
  }
  h1 {
    font-family: var(--font-family-secondary);
    font-size: 2.5rem;
    line-height: 3.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
    letter-spacing: 0.0938rem;
    code {
      font-size: 2.5rem;
      line-height: 3.75rem;
    }
  }
  h2 {
    font-family: var(--font-family-secondary);

    // font-family: var(--font-family-fourth);
    font-size: 1.9rem;
    line-height: 2.7rem;
    code {
      font-size: 1.9rem;
      line-height: 2.7rem;
    }
  }
  p {
    margin-bottom: 1rem;
    text-align: justify;
    font-weight: 300;
  }
  .article-content {
    background-color: var(--bg-article);
  }
  h3,
  h4,
  h5 {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1023px) {
  .course {
    .course-divider,
    .modules {
      display: none;
    }
    .switch-icon {
      display: block;
    }
    .content {
      max-width: 100%;
    }
    .switched {
      .modules {
        display: block;
      }
      .content {
        display: none;
      }
    }
  }
}
